import { Component } from 'react';

class FooterAD extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="footerAD">
                
            </div>
        );
    }
}

export default FooterAD; 