import React, { Component } from 'react';
import styled from "styled-components";
import axios from 'axios'
import Header from './Header';
import Biz from '../common/common';


class PlanMain extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            list : [
                // {label:"출근", location:"서울시 관악구 행운동", lat:30.0, lot:30.0}
                // , {label:"퇴근", location:"서울시 강남구 신사동", lat:30.0, lot:30.0}
            ]
            , showMap : false
            , isInitMap : false
            , initLat : 0
            , initLon : 0
            , selectedPosition : ""
            , loadingPosition : false
            
            , titleTxt : ""

            , startLb : ""
            , startLat : ""
            , startLot : ""
            , startHour : ""
            , startMinute : ""

            , destLb : ""
            , destLat : ""
            , destLot : ""
            , destHour : ""
            , destMinute : ""
        }

        this.lat    = React.createRef();
        this.lon    = React.createRef();
    }

    /**
     * 시간 리스트 반환
     * @returns 
     */
    hourList() {
        let result = []

        result.push(<option key={-1} value="" >-</option>);

        for(var i = 0; i < 24; i++) {
            result.push(
                <option key={i} value={this.addZero(i)}>{i}</option>
              )
        }
  
        return result
    }

    addZero = (num) => {
        num = parseInt(num);
        return (num >= 0 && num < 10) ? "0" + num : num + "";
    }

    /**
     * 분 리스트 반환
     * @returns 
     */
    minuteList() {
        let result = []

        result.push(<option key={-1} value="" >-</option>);

        for(var i = 0; i < 12; i++) {
            result.push(
                <option key={i*5} value={this.addZero(i*5)}>{i*5}</option>
              )
        }
  
        return result
    }

    /**
     * 일정을 추가한다.
     */
    add = (e) => {
        // console.log("add", this.state.list);

        if(this.state.titleTxt === "") {
            alert("제목을 입력하세요.");
            return;
        }
        if(this.state.startLb === "") {
            alert("출발지를 선택하세요.");
            return;
        }
        if(this.state.startHour === "" || this.state.startMinute === "") {
            alert("출발 시분을 선택하세요." + this.state.startHour);
            return;
        }
        if(this.state.destLb === "") {
            alert("도착지를 선택하세요.");
            return;
        }
        if(this.state.destHour === "" || this.state.destMinute === "") {
            alert("도착 시분을 선택하세요.");
            return;
        }

        const list  = [...this.state.list];

        const data  = {
            title : this.state.titleTxt

            , startLb : this.state.startLb
            , startLat : this.state.startLat
            , startLot : this.state.startLot
            , startHour : this.state.startHour
            , startMinute : this.state.startMinute

            , destLb : this.state.destLb
            , destLat : this.state.destLat
            , destLot : this.state.destLot
            , destHour : this.state.destHour
            , destMinute : this.state.destMinute
        };

        list.push(data);

        localStorage.setItem("plan", JSON.stringify(list));

        this.setState({
            list: list
            , titleTxt : ""

            , startLb : ""
            , startLat : ""
            , startLot : ""
            , startHour : ""
            , startMinute : ""

            , destLb : ""
            , destLat : ""
            , destLot : ""
            , destHour : ""
            , destMinute : ""
        });
    }

    changeTarget = () => {

        let startLb     = this.state.startLb;
        let startLat    = this.state.startLat;
        let startLot    = this.state.startLot;
        let startHour   = this.state.startHour;
        let startMinute = this.state.startMinute;

        let destLb      = this.state.destLb;
        let destLat     = this.state.destLat;
        let destLot     = this.state.destLot;
        let destHour    = this.state.destHour;
        let destMinute  = this.state.destMinute;
      
        this.setState({
            startLb         : destLb
            , startLat      : destLat
            , startLot      : destLot
            , startHour     : destHour
            , startMinute   : destMinute

            , destLb        : startLb
            , destLat       : startLat
            , destLot       : startLot
            , destHour      : startHour
            , destMinute    : startMinute
        });
    }

    startClickHandler = (type ,e) => {
        // let container = document.getElementById("map");
        // container.style.display = "block";

        if('geolocation' in navigator) {
        } else {
            alert("위치정보 사용이 불가합니다.");
        }

        this.setState({selectedPosition:type});

        // const getWeather    = this.getWeather;

        if(!this.isInitMap) {

            if(this.lat.current && this.lon.current) {
                this.showMap(this.lat.current, this.lon.current);
            } else {
                this.setState({loadingPosition:true})

                navigator.geolocation.getCurrentPosition((pos) => {

                    this.setState({loadingPosition:false})
    
                    this.lat.current    = pos.coords.latitude;
                    this.lon.current    = pos.coords.longitude;
                    
                    this.showMap(pos.coords.latitude, pos.coords.longitude);
                    
                }, (error) => {
                    this.setState({loadingPosition:false})
                    alert("위치권한 허용이 필요합니다.");
                    // this.setState({isInitMap: false, showMap: false});
                    
                    this.lat.current    = 37.56663496478531;
                    this.lon.current    = 126.97849384643465;

                    //서울시청
                    this.showMap(37.56663496478531, 126.97849384643465);
                });
            }
            
            this.setState({isInitMap: true, showMap: true});
        }

    }

    showMap = (lat, lon) => {

        let options = {
            center: new window.kakao.maps.LatLng(lat, lon),
            level: 8,
        };

        this.setState(
            {initLat    : lat
            , initLon   : lon}
        );
        
        let container = document.getElementById("map");

        let map = new window.kakao.maps.Map(container, options);
    
        // 지도를 클릭한 위치에 표출할 마커입니다
        var marker = new window.kakao.maps.Marker({ 
            // 지도 중심좌표에 마커를 생성합니다 
            position: map.getCenter()
        }); 
        // 지도에 마커를 표시합니다
        marker.setMap(map);
        
        let iwContent = document.createElement("div");
        iwContent.className = 'infoWindow';
        iwContent.append("위치 선택");
        iwContent.onclick = this.selectPosition;
        
        // var iwContent = '<div class="infoWindow" onclick="javascript:">선택</div>', // 인포윈도우에 표출될 내용으로 HTML 문자열이나 document element가 가능합니다
        let iwPosition = new window.kakao.maps.LatLng(lat, lon); //인포윈도우 표시 위치입니다

        // 인포윈도우를 생성합니다
        let infowindow = new window.kakao.maps.InfoWindow({
            position : iwPosition, 
            content : iwContent 
        });

        // 지도에 클릭 이벤트를 등록합니다
        // 지도를 클릭하면 마지막 파라미터로 넘어온 함수를 호출합니다
        window.kakao.maps.event.addListener(map, 'click', (mouseEvent) => {        
            
            // console.log(mouseEvent);
            // 클릭한 위도, 경도 정보를 가져옵니다 
            var latlng = mouseEvent.latLng; 
            
            // 마커 위치를 클릭한 위치로 옮깁니다
            marker.setPosition(latlng);

            infowindow.open(map, marker); 
            
            var message = '클릭한 위치의 위도는 ' + latlng.getLat() + ' 이고, ';
            message += '경도는 ' + latlng.getLng() + ' 입니다';
            
            // console.log(message);
            
            this.setState(
                {initLat: latlng.getLat()
                , initLon:latlng.getLng()}
            );
        });

        // console.log("loading kakaomap");

        setTimeout(function(){ 
            var markerPosition = marker.getPosition(); 
            map.relayout();
            map.setCenter(markerPosition); 

            infowindow.open(map, marker); 

            let infoTitle = document.querySelectorAll('.infoWindow');
            infoTitle.forEach(function(e) {
                var w = e.offsetWidth + 10;
                var ml = w/2;
                e.parentElement.style.top = "82px";
                e.parentElement.style.left = "50%";
                e.parentElement.style.marginLeft = -ml+"px";
                e.parentElement.style.width = w+"px";
                e.parentElement.previousSibling.style.display = "none";
                e.parentElement.parentElement.style.border = "0px";
                e.parentElement.parentElement.style.background = "unset";
            });

        }, 1);
        
    }

    selectPosition = (e) => {
        // this.getAddress(this.state.initLon, this.state.initLat);

        Biz.API.Address.getAddress(this.state.initLon, this.state.initLat, (data) => {

            if(this.state.selectedPosition === "start") {
                this.setState({showMap: false
                    , startLb : data.address_name
                    , startLat : this.state.initLat
                    , startLot : this.state.initLon
                });
            } else {
                this.setState({showMap: false
                    , destLb : data.address_name
                    , destLat : this.state.initLat
                    , destLot : this.state.initLon
                });
            }
        });
    }

    delPlan = (data, idx) => {
        // console.log(data, idx);

        let planData    = JSON.parse(localStorage.getItem("plan"));
        
        planData.splice(idx,1);

        localStorage.setItem("plan", JSON.stringify(planData));

        this.setState(
            {list : planData}
        );
    }

    setLb = (item) => {
        // console.log(item);

        this.setState({
            titleTxt : item.title

            , startLb : item.startLb
            , startLat : item.startLat
            , startLot : item.startLot
            , startHour : item.startHour
            , startMinute : item.startMinute

            , destLb : item.destLb
            , destLat : item.destLat
            , destLot : item.destLot
            , destHour : item.destHour
            , destMinute : item.destMinute
        });
    }


    componentDidMount() {
        
        window.gtag("event", "Page_일정");

        let list = JSON.parse(localStorage.getItem("plan"));
        if(!list) {
            list = [];
        }

        this.setState(
            {list : list}
        );
    }

    render() {
        
        const getItemLb = (item) => {

            let lb  = "";
            lb      += item.title;
            lb      += " " + item.startHour
            lb      += ":" + item.startMinute
            lb      += " ~ " + item.destHour
            lb      += ":" + item.destMinute
            lb      += " " + item.startLb.split(" ")[1]
            lb      += " > " + item.destLb.split(" ")[1]

            return lb;
        }
        const planItem = this.state.list.map(
            (item, i) => (
                <div className="planItem" key={i} onClick={()=>(this.setLb(item))}>
                    {getItemLb(item)}
                    {/* <button onClick={(e) => {e.stopPropagation(); this.delPlan(item, i);}} className="delBtn">-</button> */}
                    <img src="/img/minus.png" onClick={(e) => {e.stopPropagation(); this.delPlan(item, i);}} className="delBtn"/>
                </div>
            )
          );

        return (
            <PlanMainContainer>
                <Header></Header>
                <label className='scdFont titleLb'>일정</label><br/>
                <TitleBox>
                    <label>제목</label>
                    <br/>
                    <input type="text" onChange={(event) => this.setState({titleTxt: event.target.value})} value={this.state.titleTxt}/><br/>
                </TitleBox>
                <br/>
                <div className='mapBox'>
                    <Map style={this.state.showMap ? {display:"block"}:{display:"none"}}>
                        <div id="map" style={{width:window.innerWidth, height:window.innerWidth}}></div>
                        {/* <button onClick={this.selectPosition}>확인</button> */}
                    </Map>
                </div>
                <table>
                    <colgroup>
                        <col width="40%"></col>
                        <col width="20%"></col>
                        <col width="40%"></col>
                    </colgroup>
                    <tbody>
                        <tr>
                            <td>
                            <StartBox>
                                <label>출발</label>
                                <br/>
                                <input type="text" onClick={(e)=>{this.startClickHandler("start", e)}} defaultValue={this.state.startLb && this.state.startLb.split(" ")[1]} readOnly className='w90'/><br/>
                                <select onChange={(e) => this.setState({startHour: e.target.value})} value={this.state.startHour}>
                                    {this.hourList()}
                                </select>
                                <select onChange={(e) => this.setState({startMinute: e.target.value})} value={this.state.startMinute}>
                                    {this.minuteList()}
                                </select>
                            </StartBox>
                            </td>
                            <td>
                                <ArrowBox>
                                    <img onClick={this.changeTarget} src="/img/arrayRL.png"></img>
                                </ArrowBox>
                            </td>
                            <td>
                                <DestBox>
                                    <label>도착</label>
                                    <input type="text" onClick={(e)=>{this.startClickHandler("dest", e)}} value={this.state.destLb && this.state.destLb.split(" ")[1]} readOnly className='w90'/><br/>
                                    <select onChange={(e) => this.setState({destHour: e.target.value})} value={this.state.destHour}>
                                        {this.hourList()}
                                    </select>
                                    <select onChange={(e) => this.setState({destMinute: e.target.value})} value={this.state.destMinute}>
                                        {this.minuteList()}
                                    </select>
                                </DestBox>
                            </td>
                        </tr>
                    </tbody>
                </table>
                
                {/* <button className='addBtn' onClick={this.add}>+</button> */}
                <img src="/img/plus.png" className='addBtn' onClick={this.add}/>
                <div className='scheduleList'>
                    {planItem}
                </div>
                {
                    this.state.loadingPosition 
                    ?
                        <div className='loadingMap'>
                            <span>
                                위치 확인 중..
                            </span>
                        </div>
                    : null
                }
                
            </PlanMainContainer>
        );
    }
}

const PlanMainContainer = styled.div`
    width:100%;
    height:100%;
    
    .titleLb {
        color: rgb(64 64 64);
        font-size: 25px;
        font-weight: 600;
    }

    input {
        height:32px;
        margin:2px;
        background-color:#F2F0F0;
        border:1px solid black;
    }
    select {
        height:32px;
        margin:2px;
        background-color:#F2F0F0;
        border:1px solid black;
    }

    .addBtn {
        width: 40px;
        height: 40px;
    }

    .delBtn {
        width: 30px;
        height: 30px;
        float: right;
        position: absolute;
        right: 0;
        margin-right: 15px;
    }

    .mapBox {
        height: 100%;
        top: 0px;
        position: absolute;
        display: flex;
        align-items: center;
        z-index: 1;
    }

    .infoWindow {
        display: block;
        background: #238cfa;
        color: #fff;
        text-align: center;
        height: 24px;
        line-height: 23px;
        border-radius: 10px;
        padding: 0px 10px;
        border: 1px solid white;
    }

    .loadingMap {
        display: flex;
        position: fixed;
        top: 0;
        align-items: center;
        height: 100%;
        width: 100%;
        span {
            width: 100%;
            background-color: rgb(196 235 255 / 70%);
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: S-Core-Dream;
            font-weight: 600;
            font-size: 25px;
            color: #474444;
        }
    }
`;

const Map = styled.div`
    height: 50%;
    width: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    display:none;
    text-align:center;
    #map {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const TitleBox = styled.div`
    padding: 0 10px 0 10px;
    label {
        float:left;
        font-size:17px;
    }
    input {
        width:96%;
    }
`;

const StartBox = styled.div`
    float:left;
    input {
        width:85%;
    }
    label {
        float:left;
        font-size:17px;
        padding-left:5px;
    }
    select {
        width:43%;
    }
`;

const DestBox = styled.div`
    input {
        width:85%;
    }
    label {
        float:right;
        font-size:17px;
        padding-right:5px;
    }
    select {
        width:43%;
    }
`;

const ArrowBox = styled.div`
    img {
        width:46px;
        height:41px;
    }
`;

export default PlanMain; 