import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from "styled-components";
import Biz from '../common/common'

const Main = (props) => {

    let navagate = useNavigate();
    
    const [today, setToday] = useState(""); 

    useEffect(() => {

        window.gtag("event", "Page_메인");
        
        var now = new Date();
        let day = Biz.DateUtil.getDate();
        setToday(Biz.Const.WEEK_KOR_STR[ now.getDay()] + "요일 " + parseInt(day.substring(4,6)) + "." + parseInt(day.substring(6)));

        if(window.location.href.indexOf("go=main") == -1) {
            let list = JSON.parse(localStorage.getItem("plan"));

            if(list && list.length > 0) {
                navagate("/weather.html");
            }
        }

    }, [])

    return (
        <MainContainer>
            <div className='title'>
                <label>MICRO WEATHER</label><br/>
                <label className='todayLb'>{today}</label><br/>
            </div>
            <div className='mainDiv'>
                <Link to="/plan.html" className='menuBtn'>
                    <div>
                        <span>일정</span> <span>Schedule</span>
                    </div>
                </Link>
                <Link to="/weather.html" className="menuBtn">
                    <div>
                        <span>예보</span> <span>Casting</span>
                    </div>
                </Link>
                <Link to="/today.html" className="menuBtn">
                    {/* <div onClick={()=>(alert('준비중입니다. 누르지마세요.'))}> */}
                    <div>
                        <span>오늘</span> <span>Today</span>
                    </div>
                </Link> 
            </div>
            {/* <div className='todayWeatherDiv'>
            </div> */}
            <h3 className='bottomLb1'>개인 맞춤형 날씨 예보</h3>
            <h3 className='bottomLb2'>Personalized Weather Forecast</h3>
            {/* <Link className='about' to={"/we"}>About.</Link> */}
            <a href="mailto:nightkms@gmail.com" className='about'>contact us</a>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    width:100%;
    height:100%; 

    .title {
        padding-top: 60px;
        font-family: 'BebasNeue';
        font-size: 25px;
        .todayLb {
            font-family: 'S-CORE-DREAM';
            font-size:13px;
        }
    }
    
    .mainDiv{
        padding-top:50px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .menuBtn {
        width: 70%;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        display: flex;
        height: 75px;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        box-shadow:0px 7px 10px -7px grey;
        font-family: 'S-CORE-DREAM';
        background-color: white;
        text-decoration: none;
        span:nth-child(1) {
            color: rgb(64 64 64);
            font-size: 17px;
            font-weight: 600;
        }
        span:nth-child(2) {
            font-family:'BebasNeue';
            font-sizt:12px;
        }
    }

    .bottomLb1 {
        padding-top: 30px;
        margin: 0;
        font-family: 'S-CORE-DREAM';
        font-size: 17px;
        font-weight: 500;
    }

    .bottomLb2 {
        font-weight: 500;
        padding: 0;
        margin: 0;
        font-family: 'BebasNeue';
        font-size: 10px;
        letter-spacing: 2px;
    }
    
    .about {
        position: fixed;
        right: 0;
        bottom: 0;
        margin: 15px;
        font-family: 'BebasNeue';
        font-size: 12px;
    }
  
`

export default Main; 