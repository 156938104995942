import axios from 'axios'

const Biz = {

    Const : {
        WEEK_ENG_STR : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        , WEEK_KOR_STR : ["일", "월", "화", "수", "목", "금", "토"]
        , SHORT_WEATHER_BASE_TIME : ["0200", "0500", "0800", "1100", "1400", "1700", "2000", "2300"]
        , WEATHER_LVL : {"Sunny": 0, "Cloudy":1 , "Overcast":2 , "Snow":3 , "Rain":4 , "Shower":5 , "Rain/Snow":6}
        , MID_WEATHER_CODE : [
            {key:"서울", code : "11B00000"},
            {key:"인천", code : "11B00000"},
            {key:"경기도", code : "11B00000"},
            {key:"강원도", code : "11D20000", include:["고성", "속초", "양양", "강릉", "동해", "삼척", "태백"]},
            {key:"강원도", code : "11D10000"},
            {key:"대전", code : "11C20000"},
            {key:"세종", code : "11C20000"},
            {key:"충청남도", code : "11C20000"},
            {key:"충남", code : "11C20000"},
            {key:"충청북도", code : "11C10000"},
            {key:"충북", code : "11C10000"},
            {key:"광주", code : "11F20000"},
            {key:"전라남도", code : "11F20000"},
            {key:"전남", code : "11F20000"},
            {key:"전라북도", code : "11F10000"},
            {key:"전북", code : "11F10000"},
            {key:"대구", code : "11H10000"},
            {key:"경상북도", code : "11H10000"},
            {key:"경북", code : "11H10000"},
            {key:"부산", code : "11H20000"},
            {key:"울산", code : "11H20000"},
            {key:"경상남도", code : "11H20000"},
            {key:"경남", code : "11H20000"},
            {key:"제주도", code : "11G00000"},
        ]
        , MID_TEMP_CODE : [
            {key:'가평',code:'11B20404'},
            {key:'강계',code:'11J10005'},
            {key:'강릉',code:'11D20501'},
            {key:'강진',code:'11F20303'},
            {key:'강화',code:'11B20101'},
            {key:'개성',code:'11I20002'},
            {key:'거제',code:'11H20403'},
            {key:'거창',code:'11H20502'},
            {key:'경산',code:'11H10703'},
            {key:'경주',code:'11H10202'},
            {key:'계룡',code:'11C20403'},
            {key:'고령',code:'11H10604'},
            {key:'고산',code:'11G00501'},
            {key:'고성',code:'11D20402',include:['강원도']},
            {key:'고성',code:'11H20404',include:['경상남도']},
            {key:'고성(장전)',code:'11L10002'},
            {key:'고양',code:'11B20302'},
            {key:'고창',code:'21F10601'},
            {key:'고흥',code:'11F20403'},
            {key:'곡성',code:'11F20602'},
            {key:'공주',code:'11C20402'},
            {key:'과천',code:'11B10102'},
            {key:'광명',code:'11B10103'},
            {key:'광양',code:'11F20402'},
            {key:'광주',code:'11B20702'},
            {key:'광주',code:'11F20501'},
            {key:'괴산',code:'11C10303'},
            {key:'구례',code:'11F20601'},
            {key:'구리',code:'11B20501'},
            {key:'구미',code:'11H10602'},
            {key:'구성',code:'11J10003'},
            {key:'군산',code:'21F10501'},
            {key:'군위',code:'11H10603'},
            {key:'군포',code:'11B20610'},
            {key:'금산',code:'11C20601'},
            {key:'김제',code:'21F10502'},
            {key:'김천',code:'11H10601'},
            {key:'김포',code:'11B20102'},
            {key:'김해',code:'11H20304'},
            {key:'나주',code:'11F20503'},
            {key:'남양주',code:'11B20502'},
            {key:'남원',code:'11F10401'},
            {key:'남해',code:'11H20405'},
            {key:'논산',code:'11C20602'},
            {key:'단양',code:'11C10202'},
            {key:'담양',code:'11F20504'},
            {key:'당진',code:'11C20103'},
            {key:'대관령',code:'11D20201'},
            {key:'대구',code:'11H10701'},
            {key:'대전',code:'11C20401'},
            {key:'독도',code:'11E00102'},
            {key:'동두천',code:'11B20401'},
            {key:'동해',code:'11D20601'},
            {key:'목포',code:'21F20801'},
            {key:'무산(삼지연)',code:'11K10004'},
            {key:'무안',code:'21F20804'},
            {key:'무주',code:'11F10302'},
            {key:'문경',code:'11H10301'},
            {key:'밀양',code:'11H20601'},
            {key:'백령도',code:'11A00101'},
            {key:'보령',code:'11C20201'},
            {key:'보성',code:'11F20404'},
            {key:'보은',code:'11C10302'},
            {key:'봉화',code:'11H10402'},
            {key:'부산',code:'11H20201'},
            {key:'부안',code:'21F10602'},
            {key:'부여',code:'11C20501'},
            {key:'부천',code:'11B20204'},
            {key:'북청(신포)',code:'11K20003'},
            {key:'사리원',code:'11I10001'},
            {key:'사천',code:'11H20402'},
            {key:'삭주(수풍)',code:'11J10002'},
            {key:'산청',code:'11H20703'},
            {key:'삼척',code:'11D20602'},
            {key:'상주',code:'11H10302'},
            {key:'서귀포',code:'11G00401'},
            {key:'서산',code:'11C20101'},
            {key:'서울',code:'11B10101'},
            {key:'서천',code:'11C20202'},
            {key:'성남',code:'11B20605'},
            {key:'성산',code:'11G00101'},
            {key:'성주',code:'11H10605'},
            {key:'성진(김책)',code:'11K10003'},
            {key:'성판악',code:'11G00302'},
            {key:'세종',code:'11C20404'},
            {key:'속초',code:'11D20401'},
            {key:'수원',code:'11B20601'},
            {key:'순창',code:'11F10403'},
            {key:'순천',code:'11F20603'},
            {key:'순천시',code:'11F20405'},
            {key:'시흥',code:'11B20202'},
            {key:'신계',code:'11I10002'},
            {key:'신안',code:'21F20803'},
            {key:'신의주',code:'11J10001'},
            {key:'아산',code:'11C20302'},
            {key:'안동',code:'11H10501'},
            {key:'안산',code:'11B20203'},
            {key:'안성',code:'11B20611'},
            {key:'안양',code:'11B20602'},
            {key:'안주',code:'11J20004'},
            {key:'양구',code:'11D10202'},
            {key:'양덕',code:'11J20005'},
            {key:'양산',code:'11H20102'},
            {key:'양양',code:'11D20403'},
            {key:'양주',code:'11B20304'},
            {key:'양평',code:'11B20503'},
            {key:'여수',code:'11F20401'},
            {key:'여주',code:'11B20703'},
            {key:'연천',code:'11B20402'},
            {key:'영광',code:'21F20102'},
            {key:'영덕',code:'11H10102'},
            {key:'영동',code:'11C10402'},
            {key:'영암',code:'21F20802'},
            {key:'영양',code:'11H10403'},
            {key:'영월',code:'11D10501'},
            {key:'영주',code:'11H10401'},
            {key:'영천',code:'11H10702'},
            {key:'예산',code:'11C20303'},
            {key:'예천',code:'11H10303'},
            {key:'오산',code:'11B20603'},
            {key:'옥천',code:'11C10403'},
            {key:'완도',code:'11F20301'},
            {key:'완주',code:'11F10204'},
            {key:'용인',code:'11B20612'},
            {key:'울릉도',code:'11E00101'},
            {key:'울산',code:'11H20101'},
            {key:'울진',code:'11H10101'},
            {key:'웅기(선봉)',code:'11K10002'},
            {key:'원산',code:'11L10001'},
            {key:'원주',code:'11D10401'},
            {key:'음성',code:'11C10103'},
            {key:'의령',code:'11H20602'},
            {key:'의성',code:'11H10502'},
            {key:'의왕',code:'11B20609'},
            {key:'의정부',code:'11B20301'},
            {key:'이어도',code:'11G00601'},
            {key:'이천',code:'11B20701'},
            {key:'익산',code:'11F10202'},
            {key:'인제',code:'11D10201'},
            {key:'인천',code:'11B20201'},
            {key:'임실',code:'11F10402'},
            {key:'자성(중강)',code:'11J10004'},
            {key:'장성',code:'11F20502'},
            {key:'장수',code:'11F10301'},
            {key:'장연(용연)',code:'11I20003'},
            {key:'장진',code:'11K20002'},
            {key:'장흥',code:'11F20304'},
            {key:'전주',code:'11F10201'},
            {key:'정선',code:'11D10502'},
            {key:'정읍',code:'11F10203'},
            {key:'제주',code:'11G00201'},
            {key:'제천',code:'11C10201'},
            {key:'증평',code:'11C10304'},
            {key:'진남포(남포)',code:'11J20002'},
            {key:'진도',code:'21F20201'},
            {key:'진안',code:'11F10303'},
            {key:'진주',code:'11H20701'},
            {key:'진천',code:'11C10102'},
            {key:'창녕',code:'11H20604'},
            {key:'창원',code:'11H20301'},
            {key:'천안',code:'11C20301'},
            {key:'철원',code:'11D10101'},
            {key:'청도',code:'11H10704'},
            {key:'청송',code:'11H10503'},
            {key:'청양',code:'11C20502'},
            {key:'청주',code:'11C10301'},
            {key:'청진',code:'11K10001'},
            {key:'추자도',code:'11G00800'},
            {key:'추풍령',code:'11C10401'},
            {key:'춘천',code:'11D10301'},
            {key:'충주',code:'11C10101'},
            {key:'칠곡',code:'11H10705'},
            {key:'태백',code:'11D20301'},
            {key:'태안',code:'11C20102'},
            {key:'통영',code:'11H20401'},
            {key:'파주',code:'11B20305'},
            {key:'평강',code:'11L10003'},
            {key:'평양',code:'11J20001'},
            {key:'평창',code:'11D10503'},
            {key:'평택',code:'11B20606'},
            {key:'포천',code:'11B20403'},
            {key:'포항',code:'11H10201'},
            {key:'풍산',code:'11K20005'},
            {key:'하남',code:'11B20504'},
            {key:'하동',code:'11H20704'},
            {key:'함안',code:'11H20603'},
            {key:'함양',code:'11H20501'},
            {key:'함평',code:'21F20101'},
            {key:'함흥',code:'11K20001'},
            {key:'합천',code:'11H20503'},
            {key:'해남',code:'11F20302'},
            {key:'해주',code:'11I20001'},
            {key:'혜산',code:'11K20004'},
            {key:'홍성',code:'11C20104'},
            {key:'홍천',code:'11D10302'},
            {key:'화성',code:'11B20604'},
            {key:'화순',code:'11F20505'},
            {key:'화천',code:'11D10102'},
            {key:'횡성',code:'11D10402'},
            {key:'흑산도',code:'11F20701'},
            {key:'희천',code:'11J10006'},
        ]
    },

    StringUtil : {
        addZero : (num) => {
            num = parseInt(num);
            return (num >= 0 && num < 10) ? "0" + num : num + "";
        }
        , getTempToWindChill : (t, v) => {
            var v3 = Math.pow(v*3.6, 0.16);
            return Math.round(13.12 + 0.6215*t - (11.37*v3)+0.3965*v3*t, 0);
        }
    },

    DateUtil : {
        getDatTime : (day, type) => {
            return Biz.DateUtil.getNowDateTimeStr(day, type);
        },
        getDate : (day, type) => {
            return Biz.DateUtil.getNowDateTimeStr(day, type).substring(0,8);
        },
        getTime : (day, type) => {
            return Biz.DateUtil.getNowDateTimeStr(day, type).substring(8);
        },
        getNowDateTimeStr : (val, type) =>{
            var now = new Date();
            if(val && type) {
                if(type === "D") {
                    now.setDate(now.getDate() + val);
                } else if(type === "H") {
                    now.setHours(now.getHours() + val);
                }
            }
            return [now.getFullYear(), 
                Biz.StringUtil.addZero(now.getMonth() + 1),
                Biz.StringUtil.addZero(now.getDate()), 
                Biz.StringUtil.addZero(now.getHours()), 
                Biz.StringUtil.addZero(now.getMinutes())].join("");
        },
        getToday : () => {
            var now = new Date();
            let timeStr = '';
    
            if(now.getHours() > 12) {
                timeStr = now.getHours() - 12;
                timeStr += ":" + (now.getMinutes() > 9 ? now.getMinutes() : "0" + now.getMinutes())
                timeStr += " PM";
            } else {
                timeStr = now.getHours();
                timeStr += ":" + (now.getMinutes() > 9 ? now.getMinutes() : "0" + now.getMinutes())
                timeStr += " AM";
            }
            return Biz.Const.WEEK_ENG_STR[now.getDay()] + " " + timeStr  ;
        }, 
        getDateFormat : (dateStr) => {
            return dateStr.substring(0,4) + "년 " + dateStr.substring(4,6) + "월 " + dateStr.substring(6,8) + "일";
        },
        getHour : (day, type) => {
            return Biz.DateUtil.getNowDateTimeStr(day, type).substring(8, 10);
        }
    },

    API : {
        //기상청
        Weather : {
            //단기예보
            getShortWeather : (param, succFun, failFun) => {

                let url = "https://apis.data.go.kr/1360000/VilageFcstInfoService_2.0/getVilageFcst";
                url    += "?serviceKey=" + encodeURIComponent("TmikG+/VtvpbhG3XT0uHupQLPrbAE3/kRR1Hp2fR1uyBp6Kzbwpe36iI6lg0a3eEC/vMrYBzPj0k0hmXWdHugg==");
                url    += "&pageNo=1";
                url    += "&numOfRows=1000";
                url    += "&dataType=JSON";
                url    += "&nx=" + param.x;
                url    += "&ny=" + param.y;

                if(param.dateTime) {
                    url    += "&base_date=" + param.dateTime.substring(0, 8);
                    url    += "&base_time=" + param.dateTime.substring(8, 12);
                } else {

                    if(Biz.Const.SHORT_WEATHER_BASE_TIME[Biz.Const.SHORT_WEATHER_BASE_TIME.length - 1] < Biz.DateUtil.getTime()) {
                        url    += "&base_date=" + Biz.DateUtil.getDate();
                        url    += "&base_time=" + Biz.Const.SHORT_WEATHER_BASE_TIME[Biz.Const.SHORT_WEATHER_BASE_TIME.length - 1];
                    } else if(Biz.Const.SHORT_WEATHER_BASE_TIME[0] > Biz.DateUtil.getTime()) {
                        url    += "&base_date=" + Biz.DateUtil.getDate(-1, "D");
                        url    += "&base_time=" + Biz.Const.SHORT_WEATHER_BASE_TIME[Biz.Const.SHORT_WEATHER_BASE_TIME.length - 1];
                    } else {
                        url    += "&base_date=" + Biz.DateUtil.getDate();
                        
                        let time = "";
                        for(let i = 0; i < Biz.Const.SHORT_WEATHER_BASE_TIME.length; i++) {
                            if(parseInt(Biz.Const.SHORT_WEATHER_BASE_TIME[i]) > parseInt(Biz.DateUtil.getTime())) {
                                time    = Biz.Const.SHORT_WEATHER_BASE_TIME[i-1];
                                break;
                            }
                        }
                        url    += "&base_time=" + time; 
                    }
                }

                axios.get( url )
                .then((response) => { 
                    let timeData    = {};

                    if(response
                        && response.data
                        && response.data.response
                        && response.data.response.header
                        && response.data.response.header.resultCode === "00") {

                        let items    = response.data.response.body.items.item;

                        for(let i = 0; i < items.length; i++) {
                            if(!timeData["D" + items[i].fcstDate + items[i].fcstTime]) {
                                timeData["D" + items[i].fcstDate + items[i].fcstTime] = {};
                            } 
                            timeData["D" + items[i].fcstDate + items[i].fcstTime][items[i].category] = items[i].fcstValue;
                        }

                        succFun(timeData);
                    }

                });
            },

            //단기예보용 좌표 컨버터
            convertLatLot : (code, v1, v2)  => {
                //<!--
                //
                // LCC DFS 좌표변환을 위한 기초 자료
                //
                let RE = 6371.00877; // 지구 반경(km)
                let GRID = 5.0; // 격자 간격(km)
                let SLAT1 = 30.0; // 투영 위도1(degree)
                let SLAT2 = 60.0; // 투영 위도2(degree)
                let OLON = 126.0; // 기준점 경도(degree)
                let OLAT = 38.0; // 기준점 위도(degree)
                let XO = 43; // 기준점 X좌표(GRID)
                let YO = 136; // 기1준점 Y좌표(GRID)
                //
                // LCC DFS 좌표변환 ( code : "toXY"(위경도->좌표, v1:위도, v2:경도), "toLL"(좌표->위경도,v1:x, v2:y) )
                //
        
        
                let DEGRAD = Math.PI / 180.0;
                let RADDEG = 180.0 / Math.PI;
        
                let re = RE / GRID;
                let slat1 = SLAT1 * DEGRAD;
                let slat2 = SLAT2 * DEGRAD;
                let olon = OLON * DEGRAD;
                let olat = OLAT * DEGRAD;
        
                let ra, theta;
        
                let sn = Math.tan(Math.PI * 0.25 + slat2 * 0.5) / Math.tan(Math.PI * 0.25 + slat1 * 0.5);
                sn = Math.log(Math.cos(slat1) / Math.cos(slat2)) / Math.log(sn);
                let sf = Math.tan(Math.PI * 0.25 + slat1 * 0.5);
                sf = Math.pow(sf, sn) * Math.cos(slat1) / sn;
                let ro = Math.tan(Math.PI * 0.25 + olat * 0.5);
                ro = re * sf / Math.pow(ro, sn);
                let rs = {};
                if (code === "toXY") {
                    rs['lat'] = v1;
                    rs['lng'] = v2;
                    ra = Math.tan(Math.PI * 0.25 + (v1) * DEGRAD * 0.5);
                    ra = re * sf / Math.pow(ra, sn);
                    theta = v2 * DEGRAD - olon;
                    if (theta > Math.PI) theta -= 2.0 * Math.PI;
                    if (theta < -Math.PI) theta += 2.0 * Math.PI;
                    theta *= sn;
                    rs['x'] = Math.floor(ra * Math.sin(theta) + XO + 0.5);
                    rs['y'] = Math.floor(ro - ra * Math.cos(theta) + YO + 0.5);
                }
                else {
                    rs['x'] = v1;
                    rs['y'] = v2;
                    let xn = v1 - XO;
                    let yn = ro - v2 + YO;
                    ra = Math.sqrt(xn * xn + yn * yn);
                    if (sn < 0.0)  ra = -ra;
                    let alat = Math.pow((re * sf / ra), (1.0 / sn));
                    alat = 2.0 * Math.atan(alat) - Math.PI * 0.5;
        
                    if (Math.abs(xn) <= 0.0) {
                        theta = 0.0;
                    }
                    else {
                        if (Math.abs(yn) <= 0.0) {
                            theta = Math.PI * 0.5;
                            if (xn < 0.0) theta = - theta;
                        }
                        else theta = Math.atan2(xn, yn);
                    }
                    let alon = theta / sn + olon;
                    rs['lat'] = alat * RADDEG;
                    rs['lng'] = alon * RADDEG;
                }
                return rs;
            },
            
            //중기육상예보
            getMidWeather : (param, succFun, failFun) => {
    
                let url = "https://apis.data.go.kr/1360000/MidFcstInfoService/getMidLandFcst";
                url    += "?serviceKey=" + encodeURIComponent("TmikG+/VtvpbhG3XT0uHupQLPrbAE3/kRR1Hp2fR1uyBp6Kzbwpe36iI6lg0a3eEC/vMrYBzPj0k0hmXWdHugg==");
                url    += "&pageNo=1";
                url    += "&numOfRows=10";
                url    += "&dataType=JSON";
                url    += "&regId=" + param.regId;
    
                if(param.tmFc) {
                    url    += "&tmFc=" + param.tmFc.substring(0, 12);
                } else {
                    if(Biz.DateUtil.getTime() > 1800) {
                        url    += "&tmFc=" + Biz.DateUtil.getDate() + "1800";
                    } else if(Biz.DateUtil.getTime() > 600) {
                        url    += "&tmFc=" + Biz.DateUtil.getDate() + "0600";
                    } else {
                        url    += "&tmFc=" + Biz.DateUtil.getDate(-1, "D") + "1800";
                    }
                }
    
                axios.get( url )
                .then((response) => { 
    
                    if(response
                        && response.data
                        && response.data.response
                        && response.data.response.header
                        && response.data.response.header.resultCode === "00") {
                            
                        let items    = response.data.response.body.items.item[0];

                        let dataArr    = [];

                        for(let i = 3; i < 7; i++) {
                            let data    = {};
                            data.day    = i;
                            if(items["rnSt" + i + "Am"] > items["rnSt" + i + "Pm"]) {
                                data.rainRate   = items["rnSt" + i + "Am"];
                            } else {
                                data.rainRate   = items["rnSt" + i + "Pm"];
                            }
                            data.morning = items["wf" + i + "Am"];
                            data.afternoon = items["wf" + i + "Pm"];

                            dataArr.push(data);
                        }
    
                        succFun(dataArr);
                    }
    
                });
            },
            
            //중기기온예보
            getMidTemp : (param, succFun, failFun) => {

                let url = "https://apis.data.go.kr/1360000/MidFcstInfoService/getMidTa";
                url    += "?serviceKey=" + encodeURIComponent("TmikG+/VtvpbhG3XT0uHupQLPrbAE3/kRR1Hp2fR1uyBp6Kzbwpe36iI6lg0a3eEC/vMrYBzPj0k0hmXWdHugg==");
                url    += "&pageNo=1";
                url    += "&numOfRows=10";
                url    += "&dataType=JSON";
                url    += "&regId=" + param.regId;
    
                if(param.tmFc) {
                    url    += "&tmFc=" + param.tmFc.substring(0, 12);
                } else {
                    if(Biz.DateUtil.getTime() > 1800) {
                        url    += "&tmFc=" + Biz.DateUtil.getDate() + "1800";
                    } else if(Biz.DateUtil.getTime() > 600) {
                        url    += "&tmFc=" + Biz.DateUtil.getDate() + "0600";
                    } else {
                        url    += "&tmFc=" + Biz.DateUtil.getDate(-1, "D") + "1800";
                    }
                }
    
                axios.get( url )
                .then((response) => { 
    
                    if(response
                        && response.data
                        && response.data.response
                        && response.data.response.header
                        && response.data.response.header.resultCode === "00") {
                            
                        let items    = response.data.response.body.items.item[0];

                        let dataArr    = [];

                        for(let i = 3; i < 7; i++) {
                            let data    = {};
                            data.day    = i;
                            data.minTemp   = items["taMin" + i];
                            data.maxTemp   = items["taMax" + i];

                            dataArr.push(data);
                        }
    
                        succFun(dataArr);
                    }
    
                });
            },
        },

        Address : {
            getAddress : (x, y, succFunc) => {

                let url = "https://dapi.kakao.com/v2/local/geo/coord2regioncode.json?x=" + x + "&y=" + y;
                axios.get( url
                    , {
                        headers : {
                        "Authorization": "KakaoAK dda666088941d52fda46c9aba9abb928"
                    }
                })
                .then((response) => { 
                    let resp    = response.data
                    
                    // console.log(resp);
                    if(succFunc) {
                        succFunc(resp.documents[resp.documents.length - 1])
                    }
        
                    // if(this.state.selectedPosition === "start") {
                    //     this.setState({showMap: false
                    //         , startLb : resp.documents[resp.documents.length - 1].address_name
                    //         , startLat : y
                    //         , startLot : x
                    //     });
                    // } else {
                    //     this.setState({showMap: false
                    //         , destLb : resp.documents[resp.documents.length - 1].address_name
                    //         , destLat : y
                    //         , destLot : x
                    //     });
                    // }
                });
            }
        },
    }
}

export default Biz;