import { useEffect, useState, useRef } from 'react';
import styled from "styled-components";
import Header from './Header';
import Biz from '../common/common';

//날씨
const TodayWeather = (props) => {
    
    const [todayData, setTodayData] = useState(null);
    const [weekData, setWeekData] = useState([{}, {}, {}, {}, {}, {}, {}]);
    const [week3Data, setWeek3Data] = useState(new Array(3));
    const [week4Data, setWeek4Data] = useState(new Array(4));
    const [location, setLocation] = useState(null);
    const tempRange = useRef(null);
    const threeDayTempArr = useRef({});
    
    const [locationList, setLocationList] = useState(null);
    const [showPopup, setShowPopup] = useState(false);

    const [isShowMap, setShowMap] = useState(false);
    const [isInitMap, setInitMap] = useState(false);
    const currentLat = useRef(null);
    const currentLot = useRef(null);
    const clickedLat = useRef(null);
    const clickedLot = useRef(null);
    const selectedLocation = useRef(0);

    // 위치 추가 버튼 클릭 시
    const locationPlusClick = () => {
        setShowPopup(true);
    }

    // 위치 목록 팝업 닫기 클릭 시 
    const closePopupClick = () => {
        setShowPopup(false);
    }

    //지도 열기
    const openMapClick = () => {
        // if(!isInitMap) {

            if(currentLat.current && currentLot.current) {
                showMap(currentLat.current, currentLot.current);
                setShowMap(true);
            } else {

                navigator.geolocation.getCurrentPosition((pos) => {
    
                    currentLat.current  =   clickedLat.current  = pos.coords.latitude;
                    currentLot.current  =   clickedLot.current  = pos.coords.longitude;

                    showMap(pos.coords.latitude, pos.coords.longitude);
                    
                }, (error) => {
                    alert("위치권한 허용이 필요합니다.");
                    
                    currentLat.current  =   clickedLat.current  = 37.56663496478531;
                    currentLot.current  =   clickedLot.current  = 126.97849384643465;

                    //서울시청
                    showMap(37.56663496478531, 126.97849384643465);
                });

            }
            
            setInitMap(true);
            setShowMap(true);
        // }
    }

    //지도 닫기
    const mapCloseClick = (e) => {
        setShowMap(false);
    }

    //현재 위치 변경 시
    const locationChange = (e) => {

        let currentLabel    = "";

        if(e.target.value === "") {
            selectedLocation.current = "";
            getGeolocation();
        } else {
            
            let data    = locationList[e.target.selectedIndex - 1];

            currentLabel    = data.key;
            selectedLocation.current    = data.key;

            if(data) {
                setLocation({
                    lat     : data.lat
                    , lot   : data.lot
                });
            }
        }

        let todayLocationInfo = JSON.parse(localStorage.getItem("todayLocation"));
        todayLocationInfo.selected   = currentLabel;
        localStorage.setItem("todayLocation", JSON.stringify(todayLocationInfo));

    }

    //목록에서 삭제 클릭 시
    const delClick = (idx) => {

        let isReload    = false;
        
        let todayLocationInfo = JSON.parse(localStorage.getItem("todayLocation"));
        if(selectedLocation.current === todayLocationInfo.list[idx].key) {
            isReload    = true;
        }
        todayLocationInfo.selected   = "";
        todayLocationInfo.list.splice(idx, 1);
        localStorage.setItem("todayLocation", JSON.stringify(todayLocationInfo));

        setLocationList(todayLocationInfo.list);

        console.log(isReload)
        if(isReload) {
            init();
            getGeolocation();
        }
    }

    //현재 위치 가져오기
    const getGeolocation = () => {

        if(currentLat.current && currentLot.current) {
            setLocation({
                lat     : currentLat.current
                , lot   : currentLot.current
            });
        } else {
            navigator.geolocation.getCurrentPosition((pos) => {
                
                currentLat.current  = clickedLat.current    = pos.coords.latitude;
                currentLot.current  = clickedLot.current    = pos.coords.longitude
    
                setLocation({
                    lat     : pos.coords.latitude
                    , lot   : pos.coords.longitude
                });
                
            }, (error) => {
                alert("위치권한을 허용하거나 선호 위치를 추가해주세요.");
                
                setLocation({
                    lat     : 37.56663496478531
                    , lot   : 126.97849384643465
                });
    
            });
        }

    }

    //현재 시간부터 24시간 데이터 표시
    const getToday24HrWeather = () => {

        let xyData  = Biz.API.Weather.convertLatLot("toXY", location.lat, location.lot);
        // xyData.dateTime = Biz.DateUtil.getDate(-1, "D") + "2300"

        Biz.API.Weather.getShortWeather(xyData, (timeData) => {
            // console.log(timeData)

            let todayDataArr    = []
            let minTemp         = 100;
            let maxTemp         = -100

            for(let i = 1; i < 25; i++) {
                
                let dataKey  = "D" + Biz.DateUtil.getDatTime(i, 'H').substring(0, 10) + "00";
                // console.log(dataKey, timeData[dataKey])

                let item    = timeData[dataKey];
                let timeLb  = Biz.DateUtil.getDatTime(i, 'H').substring(8, 10);
                timeLb      =  timeLb > 12 ? "오후 " + (parseInt(timeLb) - 12) : "오전 " + parseInt(timeLb) 

                let weatherIcon;
                if(item.SKY === "1") {
                    weatherIcon = "sun.png";
                } else if(item.SKY === "3") {
                    weatherIcon = "cloudy.png";
                } else if(item.SKY === "4") {
                    weatherIcon = "overcast.png";
                }
                if(item.PTY === "1") {
                    weatherIcon = "rainy.png";
                } else if(item.PTY === "2") {
                    weatherIcon = "rainsnow.png";
                } else if(item.PTY === "3") {
                    weatherIcon = "snowy.png";
                } else if(item.PTY === "4") {
                    weatherIcon = "shower.png";
                }

                if(minTemp > parseInt(item.TMP)) {
                    minTemp = parseInt(item.TMP)
                }
                if(maxTemp < parseInt(item.TMP)) {
                    maxTemp = parseInt(item.TMP)
                }

                todayDataArr.push({
                    key : dataKey
                    , temp : parseInt(item.TMP)
                    , time : timeLb
                    , icon : weatherIcon
                    , rainRate : item.POP
                })
            }

            tempRange.current   = {low : minTemp, max : maxTemp};
            setTodayData(todayDataArr)
        });

    }

    //3일치 일자별 데이터 조회(단기예보)
    const getToday3DayWeather = () => {

        let xyData  = Biz.API.Weather.convertLatLot("toXY", location.lat, location.lot);

        xyData.dateTime = Biz.DateUtil.getDate(-1, "D") + "2300"

        Biz.API.Weather.getShortWeather(xyData, (timeData) => {
            // console.log(timeData)

            //오늘 데이터 부터 3일치 데이터를 모아서
            //날씨(오전 오후) 온도(최저 최고) 강수확률
            for(let day = 0; day < 3; day++) {
                for(let time = 0; time < 24; time++) {
                    let dateKey = "D" + Biz.DateUtil.getDate(day, "D");
                    let dataKey = "D" + Biz.DateUtil.getDate(day, "D") + Biz.StringUtil.addZero(time) + "00";
                    let item    = timeData[dataKey];
                    let dayData;

                    if(dataKey.indexOf(dateKey) === 0) {
                        // console.log(item);

                        //요기랑 비교하면 됨 threeDayTempArr.current[dateKey]
                        if(!threeDayTempArr.current[dateKey]) {
                            threeDayTempArr.current[dateKey] = {};
                            threeDayTempArr.current[dateKey].rainRate   = 0;
                            threeDayTempArr.current[dateKey].minTemp    = 100;
                            threeDayTempArr.current[dateKey].maxTemp    = -100;
                            threeDayTempArr.current[dateKey].morning    = 0;
                            threeDayTempArr.current[dateKey].afternoon  = 0;
                        }
                        
                        dayData = threeDayTempArr.current[dateKey];

                        // 오늘
                        if(day === 0) {
                            dayData.dayStr  = "오늘";
                        } else {
                            var now = new Date();
                            let dayIdx  = now.getDay() + day;
                            dayIdx      = dayIdx >= 7 ? dayIdx - 7 : dayIdx;
                            dayData.dayStr  = Biz.Const.WEEK_KOR_STR[ dayIdx] + "요일";
                        }

                        let precipitation;
                        let weatherType;
                        let weatherIcon;

                        if(item.SKY === "1") {
                            weatherType = "Sunny";  //맑음
                            weatherIcon = "sun.png";
                        } else if(item.SKY === "3") {
                            weatherType = "Cloudy"; //구름많음
                            weatherIcon = "cloudy.png";
                        } else if(item.SKY === "4") {
                            weatherType = "Overcast";   //흐림
                            weatherIcon = "overcast.png";
                        }
                        // weatherData.PTY = '2'
                        if(item.PTY === "1") {
                            // rainType = "비";
                            weatherType = "Rain";   //비
                            weatherIcon = "rainy.png";
                        } else if(item.PTY === "2") {
                            // rainType = "비/눈";
                            weatherType = "Rain/Snow";   //비/눈
                            weatherIcon = "rainsnow.png";
                        } else if(item.PTY === "3") {
                            // rainType = "눈";
                            weatherType = "Snow";   //눈
                            weatherIcon = "snowy.png";
                        } else if(item.PTY === "4") {
                            // rainType = "소나기";
                            weatherType = "Shower";   //소나기
                            weatherIcon = "shower.png";
                        }

                        if(item.PCP === "강수없음") {
                            precipitation   = "";
                        } else {
                            precipitation   = item.PCP;
                        }
            
                        if(weatherType === "Snow") {
                            precipitation   = item.SNO;
                        }

                        // 강수확률
                        if(threeDayTempArr.current[dateKey].rainRate < parseInt(item.POP)) {
                            dayData.rainRate    = parseInt(item.POP);
                        }

                        // 최저온도
                        if(threeDayTempArr.current[dateKey].minTemp > parseInt(item.TMP)) {
                            dayData.minTemp = parseInt(item.TMP)
                        }

                        // 최고온도
                        if(threeDayTempArr.current[dateKey].maxTemp < parseInt(item.TMP)) {
                            dayData.maxTemp = parseInt(item.TMP)
                        }

                        // 오전 날씨
                        // console.log(parseInt(dataKey.replace(dateKey, "").substring(0, 2)))
                        if(parseInt(dataKey.replace(dateKey, "").substring(0, 2)) < 12) {
                            if(threeDayTempArr.current[dateKey].morning < Biz.Const.WEATHER_LVL[weatherType]) {
                                dayData.morning = Biz.Const.WEATHER_LVL[weatherType]
                            }
                        } else {
                            // 오후 날씨
                            if(threeDayTempArr.current[dateKey].afternoon < Biz.Const.WEATHER_LVL[weatherType]) {
                                dayData.afternoon = Biz.Const.WEATHER_LVL[weatherType]
                            }
                        }
                    }

                    threeDayTempArr.current[dateKey]    = dayData;
                }
            }
            
            let weekDataArr = week3Data.slice(0);
            for(let day = 0; day < 3; day++) {
                let dataKey = "D" + Biz.DateUtil.getDate(day, "D");

                threeDayTempArr.current[dataKey].morning = getWeatherIcon(threeDayTempArr.current[dataKey].morning);
                threeDayTempArr.current[dataKey].afternoon = getWeatherIcon(threeDayTempArr.current[dataKey].afternoon);

                weekDataArr[day]  = threeDayTempArr.current[dataKey];
            }
            setWeek3Data(weekDataArr)
        });
        
    }

    //중기예보 조회 (3일 후 데이터)
    const getToday4DayWeather = () => {
        //현재 위치의 주소를 가져오고
        Biz.API.Address.getAddress(location.lot, location.lat, (data) => {
            if(data && data.address_name) {
                //중기육상예보
                for(let i = 0; i < Biz.Const.MID_WEATHER_CODE.length; i++) {
                    let item    = Biz.Const.MID_WEATHER_CODE[i];

                    if(data.address_name.indexOf(item.key) > -1) {
                        //강원도는 영서, 영동으로 구분되어 있음.
                        let isMatch = true;
                        if(item.include) {
                            isMatch = false;
                            item.include.forEach(val => {
                                if(data.address_name.indexOf(val) > -1) {
                                    isMatch = true;
                                }
                            });
                        }
                        if(isMatch) {
                            Biz.API.Weather.getMidWeather({regId:item.code}, (data) => {

                                let weekDataArr = week4Data;
                                for(let i = 0; i < 4; i++) {

                                    let item    = data[i];

                                    let dayData = weekDataArr[i];
    
                                    if(!dayData) {
                                        dayData = {};
                                        dayData.rainRate   = 0;
                                        dayData.minTemp    = "-";
                                        dayData.maxTemp    = "-";
                                        dayData.morning    = 0;
                                        dayData.afternoon  = 0;
                                    }
                                    
                                    var now = new Date();
                                    let dayIdx  = now.getDay() + item.day;
                                    dayIdx      = dayIdx >= 7 ? dayIdx - 7 : dayIdx;
                                    dayData.dayStr  = Biz.Const.WEEK_KOR_STR[dayIdx] + "요일";
                                    
                                    dayData.rainRate    = item.rainRate
    
                                    dayData.morning     = getWeatherIconForKor(item.morning)
                                    dayData.afternoon   = getWeatherIconForKor(item.afternoon)
    
                                    weekDataArr[i]      = dayData;
                                }
                                setWeek4Data([...weekDataArr]);
                                
                            });
                            break;
                        }
                    }
                }

                //중기기온예보
                for(let i = 0; i < Biz.Const.MID_TEMP_CODE.length; i++) {
                    let item    = Biz.Const.MID_TEMP_CODE[i];
    
                    if(data.address_name.indexOf(item.key) > -1) {
                        let isMatch = true;
                        if(item.include) {
                            isMatch = false;
                            item.include.forEach(val => {
                                if(data.address_name.indexOf(val) > -1) {
                                    isMatch = true;
                                }
                            });
                        }
                        if(isMatch) {
                            Biz.API.Weather.getMidTemp({regId:item.code}, (data) => {

                                let weekDataArr = week4Data;
                                for(let i = 0; i < 4; i++) {

                                    let item    = data[i];

                                    let dayData = weekDataArr[i];
    
                                    if(!dayData) {
                                        dayData = {};
                                        dayData.minTemp    = "-";
                                        dayData.maxTemp    = "-";
                                        dayData.rainRate    = "-"
                                        dayData.morning     = "none.png"
                                        dayData.afternoon   = "none.png"
                                    }
                                    
                                    var now = new Date();
                                    let dayIdx  = now.getDay() + item.day;
                                    dayIdx      = dayIdx >= 7 ? dayIdx - 7 : dayIdx;
                                    dayData.dayStr  = Biz.Const.WEEK_KOR_STR[dayIdx] + "요일";
                                    
                                    dayData.minTemp    = item.minTemp;
                                    dayData.maxTemp    = item.maxTemp;
    
                                    weekDataArr[i]      = dayData;
                                }
                                setWeek4Data([...weekDataArr]);
                                
                            });
                            break;

                        }
                    }
                }

            }

        });
    }

    // 날씨타입별 아이콘 조회
    const getWeatherIcon = (type) => {
        if(type === 0) {
            return "sun.png";
        } else if(type === 1) {
            return "cloudy.png";
        } else if(type === 2) {
            return "overcast.png";
        } else if(type === 3) {
            return "snowy.png";
        } else if(type === 4) {
            return "rainy.png";
        } else if(type === 5) {
            return "shower.png";
        } else if(type === 6) {
            return "rainsnow.png";
        }
    }

    // 날씨타입별 아이콘 조회
    const getWeatherIconForKor = (val) => {
        if(val === "맑음") {
            return "sun.png";
        } else if(val === "구름많음") {
            return "cloudy.png";
        } else if(val === "흐림") {
            return "overcast.png";
        } else if(val.indexOf("눈") > -1) {
            return "snowy.png";
        } else if(val.indexOf("비") > -1) {
            return "rainy.png";
        } else if(val.indexOf("소나기") > -1) {
            return "shower.png";
        } else if(val.indexOf("비/눈") > -1) {
            return "rainsnow.png";
        }
    }

    //위치 변경 시 데이터 초기화
    const init = () => {
        setTodayData(null);
        setWeekData([{}, {}, {}, {}, {}, {}, {}]);
        setWeek3Data(new Array(3));
        setWeek4Data(new Array(4));
        tempRange.current       = null;
        threeDayTempArr.current = {};
    }

    const showMap = (lat, lon) => {

        let options = {
            center: new window.kakao.maps.LatLng(lat, lon),
            level: 8,
        };
        
        let container = document.getElementById("map");

        let map = new window.kakao.maps.Map(container, options);
    
        // 지도를 클릭한 위치에 표출할 마커입니다
        var marker = new window.kakao.maps.Marker({ 
            // 지도 중심좌표에 마커를 생성합니다 
            position: map.getCenter()
        }); 
        // 지도에 마커를 표시합니다
        marker.setMap(map);
        
        let iwContent = document.createElement("div");
        iwContent.className = 'infoWindow';
        iwContent.append("위치 선택");
        iwContent.onclick = selectPosition;
        
        // var iwContent = '<div class="infoWindow" onclick="javascript:">선택</div>', // 인포윈도우에 표출될 내용으로 HTML 문자열이나 document element가 가능합니다
        let iwPosition = new window.kakao.maps.LatLng(lat, lon); //인포윈도우 표시 위치입니다

        // 인포윈도우를 생성합니다
        let infowindow = new window.kakao.maps.InfoWindow({
            position : iwPosition, 
            content : iwContent 
        });

        // 지도에 클릭 이벤트를 등록합니다
        // 지도를 클릭하면 마지막 파라미터로 넘어온 함수를 호출합니다
        window.kakao.maps.event.addListener(map, 'click', (mouseEvent) => {        
            
            // console.log(mouseEvent);
            // 클릭한 위도, 경도 정보를 가져옵니다 
            var latlng = mouseEvent.latLng; 
            
            // 마커 위치를 클릭한 위치로 옮깁니다
            marker.setPosition(latlng);

            infowindow.open(map, marker); 
            
            var message = '클릭한 위치의 위도는 ' + latlng.getLat() + ' 이고, ';
            message += '경도는 ' + latlng.getLng() + ' 입니다';
            
            clickedLat.current  = latlng.getLat();
            clickedLot.current  = latlng.getLng();
            
        });

        // console.log("loading kakaomap");

        setTimeout(function(){ 
            var markerPosition = marker.getPosition(); 
            map.relayout();
            map.setCenter(markerPosition); 

            infowindow.open(map, marker); 

            let infoTitle = document.querySelectorAll('.infoWindow');
            infoTitle.forEach(function(e) {
                var w = e.offsetWidth + 10;
                var ml = w/2;
                e.parentElement.style.top = "82px";
                e.parentElement.style.left = "50%";
                e.parentElement.style.marginLeft = -ml+"px";
                e.parentElement.style.width = w+"px";
                e.parentElement.previousSibling.style.display = "none";
                e.parentElement.parentElement.style.border = "0px";
                e.parentElement.parentElement.style.background = "unset";
            });

        }, 1);
        
    }

    const selectPosition = (e) => {

        // console.log(clickedLot.current, clickedLat.current)

        Biz.API.Address.getAddress(clickedLot.current, clickedLat.current, (data) => {

            let todayLocationInfo = JSON.parse(localStorage.getItem("todayLocation"));

            if(todayLocationInfo) {
                if(todayLocationInfo.list) {
                    todayLocationInfo.list.push({
                        type : "add",
                        label : data.address_name,
                        lat : clickedLat.current,
                        lot : clickedLot.current,
                        key : new Date().getTime()
                    });
                } else {
                    todayLocationInfo.list = [{
                        type : "add",
                        label : data.address_name,
                        lat : clickedLat.current,
                        lot : clickedLot.current,
                        key : new Date().getTime()
                    }];
                }
            } else {
                todayLocationInfo = {};

                todayLocationInfo.list = [{
                    type : "add",
                    label : data.address_name,
                    lat : clickedLat.current,
                    lot : clickedLot.current,
                    key : new Date().getTime()
                }];
            }

            localStorage.setItem("todayLocation", JSON.stringify(todayLocationInfo))
            setLocationList(todayLocationInfo.list);
        });

        setShowMap(false);
    }


    useEffect(() => {

        init();

        if(location) {
            getToday24HrWeather();
            getToday3DayWeather();
            getToday4DayWeather();
        }

    }, [location])

    useEffect(() => {
        let data    = new Array(7);
        if(week3Data || week4Data) {
            for(let i = 0; i < 7; i++) {
                if(i < 3) {
                    if(week3Data[i]) {
                        data.push(week3Data[i]);
                    } else {
                        data.push({});
                    }
                } else {
                    if(week4Data[i - 3]) {
                        data.push(week4Data[i - 3]);
                    } else {
                        data.push({});
                    }
                }
            }
        }
        setWeekData(data);

    }, [week3Data, week4Data])
    
    useEffect(() => {
        
        init();

        window.gtag("event", "Page_오늘");

        threeDayTempArr.current = {};

        let todayLocationInfo = JSON.parse(localStorage.getItem("todayLocation"));

        if(todayLocationInfo) {
            if(todayLocationInfo.selected === "") {
                getGeolocation();
            } else {
                selectedLocation.current    = todayLocationInfo.selected;
            }
            setLocationList(todayLocationInfo.list);
            if(todayLocationInfo.list) {
                for(let i = 0; i < todayLocationInfo.list.length; i++) {
                    if(todayLocationInfo.list[i].key === todayLocationInfo.selected) {
                        setLocation({
                            lat     : todayLocationInfo.list[i].lat
                            , lot   : todayLocationInfo.list[i].lot
                        })
                    }
                }
            }
        } else {
            getGeolocation();
        }

    }, [])

    return (
        <TodayWeatherContainer>
            <Header></Header>
            <label className='scdFont titleLb'>오늘</label><br/>
            <br/>
            <select onChange={locationChange} value={selectedLocation.current}>
                <option value="">현재 위치</option>
                {
                locationList
                ? locationList.map(
                    (item, i) => (
                        <option key={item.label + i} value={item.key} data={item}>{item.label}</option>
                    )
                )
                : null
                }
            </select>
            <span onClick={locationPlusClick}> <img className='settingImg' src="/img/setting.png"/></span>
            {/* <select>
                {
                    locationList
                    ? locationList.map(
                        (item, i) => (
                            <option key={item.label + i} data={item}>{item.label}</option>
                        )
                    )
                    : null
                }
            </select> */}
            
            <div className='todayDiv roundBox'>
            {
                todayData
                ?
                todayData.map(
                    (item, i) => (
                        <div className='todayItemDiv' key={item.time}>
                            <div>
                                <label>{item.time}시</label><br/>
                                <img src={"/img/" + item.icon}></img><br/>
                                <label className={tempRange.current.low === item.temp ? "blueCor" : tempRange.current.max === item.temp ? "redCor" : ""}>{item.temp}<label className='topTxt'>o</label></label><br/>
                                <label>{item.rainRate}<label className='topTxt'>%</label></label>
                            </div>
                        </div>
                    )
                )
                : null
            }
            </div>

            <div className='weekDiv roundBox'>
            {
                weekData
                ? 
                weekData.map((item, i) => {
                    if(item.dayStr) {
                        return <div key={i+item.dayStr}>
                            <label>{item.dayStr}</label>
                            <label>{item.rainRate}<label className='topTxt'>%</label></label>
                            <img src={"/img/" + item.morning}></img>
                            <img src={"/img/" + item.afternoon}></img>
                            <label>{item.minTemp}<label className='topTxt'>o</label></label>
                            <label>{item.maxTemp}<label className='topTxt'>o</label></label>
                        </div>
                    } else {
                        return <div key={i}></div>
                    }
                        
                })
                : null
            }
            </div>
            {
                showPopup
                ? <PopupContainer>
                    <div className='header'>
                        위치 목록
                        <div className='closeBtn' onClick={closePopupClick}><img src="/img/close.png"/></div>
                    </div>
                    <div className='body'>
                        {
                            locationList
                            ? locationList.map(
                                (item, i) => (
                                    <span key={item.label + i}>
                                        <label>{item.label}</label> {item.type !== "curr" ? <label onClick={() => delClick(i)}> <button className='delBtn'>-</button> </label> : null}
                                        <br/>
                                    </span>
                                )
                            )
                            : null
                        }
                        <label onClick={openMapClick}> <button className='addBtn'>위치 추가</button> </label>
                    </div>
                </PopupContainer>
                : null 
            }

            <div className='mapBox'>
                <Map style={isShowMap ? {display:"block"}:{display:"none"}}>
                    <div>
                        <div id="map" style={{width:window.innerWidth, height:window.innerWidth}}></div>
                        <img src="/img/close.png" onClick={mapCloseClick}/>
                    </div>
                </Map>
            </div>
        </TodayWeatherContainer>
    );
}

const TodayWeatherContainer = styled.div`
    .titleLb {
        color: rgb(64 64 64);
        font-size: 25px;
        font-weight: 600;
    }

    .positionLb {
        text-decoration: underline;
    }

    .settingImg {
        vertical-align: middle;
    }

    .todayDiv {
        height: 120px;
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        align-items: center;
    }

    .weekDiv {
        // height: 300px;
        height: 290px;
        >div {
            width: 100%;
            height: 30px;
            display: flex;
            margin: 10px 5px;
            align-items: center;
            >label {
                width: 10%;
                padding:0px 5px;
                font-family: "Roboto";
            }
            >label:nth-child(1) {
                width: 35%;
                font-family: S-CORE-DREAM;
            }
            img {
                width:30px;
            }
        }
    }

    .roundBox {
        margin: 15px;
        border: 1px solid black;
        border-radius: 10px;
        background-color: white;
    }

    .todayItemDiv {
        div {
            width:60px;
            >label:nth-child(1) {
                font-size: 11px;
                font-family: 'S-CORE-DREAM';
            }
        }
        font-family: "Roboto";
        padding: 0px 1px;
        img {
            width:35px;
        }
    }
    
    .topTxt {
        font-size: 10px;
        vertical-align: top;
    }

    .blueCor {
        border-radius: 50%;
        background-color: #0000ff33;
        width: 22px;
        display: inline-block;
    }

    .redCor {
        background-color: #ff000033;
        border-radius: 50%;
        width: 22px;
        display: inline-block;
    }

    .mapBox {
        height: 100%;
        top: 0px;
        position: absolute;
        display: flex;
        align-items: center;
        z-index: 1;
    }
    .infoWindow {
        display: block;
        background: #238cfa;
        color: #fff;
        text-align: center;
        height: 24px;
        line-height: 23px;
        border-radius: 10px;
        padding: 0px 10px;
        border: 1px solid white;
    }
`;

const PopupContainer = styled.div`
    height:50%;
    width:100%;
    border: 1px solid black;
    position: fixed;
    top: 25%;
    background-color:white;
    display: flex;
    flex-direction: column;

    .header {
        height:50px;
        width:100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        .closeBtn {
            position: absolute;
            right: 5px;
            img {
                height: 20px;
            }
        }
    }

    .body {
        overflow-y: auto;
        flex:1;
        .delBtn {
            background: red;
            color: #fff;
            text-align: center;
            height: 24px;
            line-height: 23px;
            border-radius: 5px;
            padding: 0px 10px;
            border: 1px solid white;
        }
        .addBtn {
            background: #238cfa;
            color: #fff;
            text-align: center;
            height: 24px;
            line-height: 23px;
            border-radius: 5px;
            padding: 0px 10px;
            border: 1px solid white;
            font-family: S-Core-Dream;
        }
    }
`;

const Map = styled.div`
    height: 50%;
    width: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    display:none;
    text-align:center;
    div {
        position: relative;
        img {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 10;
        }
    }
    #map {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export default TodayWeather; 