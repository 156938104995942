import Main from './component/Main';
import FooterAD from './component/FooterAD';
import WeatherMain from './component/WeatherMain';
import PlanMain from './component/PlanMain';
import WeatherInfo from './component/WeatherInfo';
import TodayWeather from './component/TodayWeather';
import We from './component/We';
import './App.css';
import './common.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {

  return (
      <>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Main />}></Route>
            <Route path="/weather.html" element={<WeatherMain />}></Route>
            <Route path="/plan.html" element={<PlanMain />}></Route>
            <Route path="/weatherinfo.html" element={<WeatherInfo />}></Route>
            <Route path="/we" element={<We />}></Route>
            <Route path="/today.html" element={<TodayWeather />}></Route>
          </Routes>
        </BrowserRouter>
        {/* <FooterAD></FooterAD> */}
      </>
  );
}

export default App;
