import { Component } from 'react';
import axios from 'axios'
import styled from "styled-components";
import Biz from '../common/common';

class WeatherItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            title : ""

            , startTemp : ""
            , startWeather : ""
            , startWeatherImage : ""
            , startRainRate : ""
            , startPrecipitation : ""
            , startDate : ""

            , destTemp : ""
            , destWeather : ""
            , destWeatherImage : ""
            , destRainRate : ""
            , destPrecipitation : ""
            , destDate : ""
        }
    }

    getWeather = (data, target) => {

        // console.log(this.props);
        
        let xyData  = Biz.API.Weather.convertLatLot("toXY",data.lat, data.lot);
        // console.log(xyData);

        Biz.API.Weather.getShortWeather(xyData, (timeData) => {

            // console.log(this.props, timeData);
            // POP	강수확률	%
            // PTY	강수형태	코드값
            // PCP	1시간 강수량	범주 (1 mm)
            // REH	습도	%
            // SNO	1시간 신적설	범주(1 cm)
            // SKY	하늘상태	코드값
            // TMP	1시간 기온	℃
            // TMN	일 최저기온	℃
            // TMX	일 최고기온	℃
            // UUU	풍속(동서성분)	m/s
            // VVV	풍속(남북성분)	m/s
            // WAV	파고	M
            // VEC	풍향	deg
            // WSD	풍속	m/s
            
            let weatherData = {};
            let isNextDay   = false;

            if(this.props.data.startHour + this.props.data.startMinute > 
                this.props.data.destHour + this.props.data.destMinute) {
                isNextDay   = true;
            }
            
            if(Biz.DateUtil.getHour() <= Biz.StringUtil.addZero(this.props.data.startHour)) {
                //지금 시간이 start 시간 이전이면 
                //오늘 데이터로 데이터 셋팅
                if(target === "start") {
                    weatherData = timeData["D" + Biz.DateUtil.getDate() + Biz.StringUtil.addZero(this.props.data.startHour) + "00"];
                    weatherData.date    = "D" + Biz.DateUtil.getDate() + Biz.StringUtil.addZero(this.props.data.startHour) + "00";
                } else {
                    if(isNextDay) {
                        weatherData = timeData["D" + Biz.DateUtil.getDate(1, 'D') + Biz.StringUtil.addZero(this.props.data.destHour) + "00"];
                        weatherData.date    = "D" + Biz.DateUtil.getDate(1, 'D') + Biz.StringUtil.addZero(this.props.data.destHour) + "00";
                    } else {
                        weatherData = timeData["D" + Biz.DateUtil.getDate() + Biz.StringUtil.addZero(this.props.data.destHour) + "00"];
                        weatherData.date    = "D" + Biz.DateUtil.getDate() + Biz.StringUtil.addZero(this.props.data.destHour) + "00";
                    }
                }


            } else if(Biz.DateUtil.getHour() >= Biz.StringUtil.addZero(this.props.data.destHour)) {
                //지금 시간이 dest 시간보다 이후이면
                //다음날 데이터에서 해당시간 데이터 조회
                // console.log("dest 도착 후", this.props.data.title);
                // console.log("D" + this.getDate(1, "D") + Biz.StringUtil.addZero(this.props.data.startHour) + "00");

                if(target === "start") {
                    weatherData = timeData["D" + Biz.DateUtil.getDate(1, "D") + Biz.StringUtil.addZero(this.props.data.startHour) + "00"];
                    weatherData.date    = "D" + Biz.DateUtil.getDate(1, "D") + Biz.StringUtil.addZero(this.props.data.startHour) + "00";
                } else {
                    weatherData = timeData["D" + Biz.DateUtil.getDate(1, "D") + Biz.StringUtil.addZero(this.props.data.destHour) + "00"];
                    weatherData.date    = "D" + Biz.DateUtil.getDate(1, "D") + Biz.StringUtil.addZero(this.props.data.destHour) + "00";
                }
                
                // if(target === "dest" && isNextDay) {
                //     weatherData = timeData["D" + this.getDate(2, "D") + Biz.StringUtil.addZero(this.props.data.startHour) + "00"];
                //     weatherData.date    = this.getDateFormat(this.getDate(2, "D"));
                // }

            } else  {
                //지금 시간이 start, dest 사이에 있으면
                //시작시간은 현재 시간 기준 데이터 표시
                // console.log("dest 가는중", this.props.data.title);
                
                if(target === "start") {
                    if(this.props.data.startHour >= "2300") {
                        weatherData = timeData["D" + Biz.DateUtil.getDate(1, "H") + "0000"];
                        weatherData.date    = "D" + Biz.DateUtil.getDate(1, "H") + "0000";
                    } else {
                        weatherData = timeData["D" + Biz.DateUtil.getDate() + Biz.DateUtil.getTime(1, "H").substring(0,2) + "00"];
                        weatherData.date    = "D" + Biz.DateUtil.getDate() + Biz.DateUtil.getTime(1, "H").substring(0,2) + "00";
                    }
                } else {
                    if(this.props.data.destHour >= "2300") {
                        weatherData = timeData["D" + Biz.DateUtil.getDate(1, "H") + "0000"];
                        weatherData.date    = "D" + Biz.DateUtil.getDate(1, "H") + "0000";
                    } else {
                        weatherData = timeData["D" + Biz.DateUtil.getDate() + Biz.StringUtil.addZero(this.props.data.destHour) + "00"];
                        weatherData.date    = "D" + Biz.DateUtil.getDate() + Biz.StringUtil.addZero(this.props.data.destHour) + "00";
                    }
                }
                // console.log(weatherData.date);
                
                if(target === "dest" && isNextDay) {
                    weatherData = timeData["D" + Biz.DateUtil.getDate(1, 'D') + Biz.StringUtil.addZero(this.props.data.destHour) + "00"];
                    weatherData.date    = "D" + Biz.DateUtil.getDate(1, 'D') + Biz.StringUtil.addZero(this.props.data.destHour) + "00";
                }
            }
            
            // console.log(weatherData);

            let precipitation   = ""; //강수량
            let weatherType     = ""; //하늘상태
            let weatherIcon     = ""; //하늘상태 아이콘
            // let rainType        = ""; //강수형태

            if(weatherData.SKY === "1") {
                weatherType = "Sunny";  //맑음
                weatherIcon = "sun.png";
            } else if(weatherData.SKY === "3") {
                weatherType = "Cloudy"; //구름많음
                weatherIcon = "cloudy.png";
            } else if(weatherData.SKY === "4") {
                weatherType = "Overcast";   //흐림
                weatherIcon = "overcast.png";
            }
            // weatherData.PTY = '2'
            if(weatherData.PTY === "1") {
                // rainType = "비";
                weatherType = "Rain";   //비
                weatherIcon = "rainy.png";
            } else if(weatherData.PTY === "2") {
                // rainType = "비/눈";
                weatherType = "Rain/Snow";   //비/눈
                weatherIcon = "rainsnow.png";
            } else if(weatherData.PTY === "3") {
                // rainType = "눈";
                weatherType = "Snow";   //눈
                weatherIcon = "snowy.png";
            } else if(weatherData.PTY === "4") {
                // rainType = "소나기";
                weatherType = "Shower";   //소나기
                weatherIcon = "shower.png";
            }

            if(weatherData.PCP === "강수없음") {
                precipitation   = "";
            } else {
                precipitation   = weatherData.PCP;
            }

            if(weatherType === "Snow") {
                precipitation   = weatherData.SNO;
            }
            
            if(precipitation !== "") {
                precipitation   = precipitation.replaceAll("mm", "㎜")
                precipitation   = precipitation.replaceAll("cm미만", "㎝↓")
                precipitation   = precipitation.replaceAll("cm", "㎝")
            }

            let dateData    = {};
            
            if(target === "start") {
                dateData.startTemp  = weatherData.TMP;
                dateData.startWeather  = weatherType;
                dateData.startRainRate  = weatherData.POP;
                dateData.startPrecipitation  = precipitation;
                dateData.startDate  = weatherData.date;
                dateData.startWeatherIcon  = weatherIcon;
                dateData.startTime  = this.props.data.startHour + this.props.data.startMinute;
                dateData.startHumidity  = weatherData.REH;
                dateData.startWindSpeed  = weatherData.WSD;
            } else {
                dateData.destTemp  = weatherData.TMP;
                dateData.destWeather  = weatherType;
                dateData.destRainRate  = weatherData.POP
                dateData.destPrecipitation  = precipitation;
                dateData.destDate  = weatherData.date;
                dateData.destWeatherIcon  = weatherIcon;
                dateData.destTime  = this.props.data.destHour + this.props.data.destMinute;
                dateData.destHumidity  = weatherData.REH;
                dateData.destWindSpeed  = weatherData.WSD;
            }
            
            this.props.onLoad({...dateData});
            this.setState(dateData);

        });
        
    }

    componentDidMount() {
        // console.log("WeatherItem componentDidMount", this.props);
        this.setState({
            title : this.props.data.title
        });
        // console.log(this.props.data);

        this.getWeather({
            lat : this.props.data.startLat
            , lot : this.props.data.startLot
        }, "start");
        
        this.getWeather({
            lat : this.props.data.destLat
            , lot : this.props.data.destLot
        }, "dest");
    }

    render() { //HTML을 웹 페이지에 렌더링 한다. 
        // const { data } = this.props;
        return (
            <WeatherItemContainer>
                <table>
                    <colgroup>
                        <col width="30%"></col>
                        <col width="20%"></col>
                        <col width="30%"></col>
                        <col width="20%"></col>
                    </colgroup>
                    <tbody>

                        <tr>
                            <td colSpan={4}><b className='sCoreDream'>{this.state.title}</b></td>
                        </tr>
                        {/* <tr className='topLineTr'></tr> */}
                        <tr className='weatherTr'>
                            <td className='alignC verticalLineTd'>
                                {this.state.startWeatherIcon === undefined ? null
                                    : <img src={"/img/" + this.state.startWeatherIcon} alt="출발 날씨 아이콘"
                                    onClick={(e) => alert(this.state.startDate)}></img>
                                }
                                {/* <p className='weatherLb'>{this.state.startWeather}</p> */}
                            </td>
                            <td className='alignR'>
                                {this.state.startTemp}
                                {
                                    this.state.startTemp
                                    ? <label className='topTxt'>o</label>
                                    : null
                                }
                                <br/>
                                {this.state.startRainRate}
                                {
                                    this.state.startRainRate
                                    ? <label className='topTxt'>%</label>
                                    : null
                                }
                                {
                                    this.state.startPrecipitation === "" ? null
                                    : <>
                                    <br/>
                                    <label className='sm'>
                                        {this.state.startPrecipitation}
                                    </label>
                                    </>
                                }
                            </td>
                            <td className='alignC verticalLineTd'>
                                <div className='verticalLine'></div>
                                {this.state.destWeatherIcon === undefined ? null
                                    : <img src={"/img/" + this.state.destWeatherIcon} alt="도착 날씨 아이콘"
                                    onClick={(e) => alert(this.state.destDate)}></img>
                                }<br/>
                                {/* <p className='weatherLb'>{this.state.destWeather}</p> */}
                            </td>
                            <td className='alignR'>
                                {this.state.destTemp}
                                {
                                    this.state.destTemp
                                    ? <label className='topTxt'>o</label>
                                    : null
                                }
                                <br/>
                                {this.state.destRainRate}
                                {
                                    this.state.destRainRate
                                    ?  <label className='topTxt'>%</label>
                                    : null
                                }
                                {
                                    this.state.destPrecipitation === "" ? null
                                    : <>
                                    <br/>
                                    <label className='sm'>
                                        {this.state.destPrecipitation}
                                    </label>
                                    </>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </WeatherItemContainer>
        );
    }
}

const WeatherItemContainer = styled.div`
    padding: 0 20px 20px 20px;
    font-size:20px;

    table {
        width:100%;
        text-align:left;
        border-collapse: collapse;
        background-clip: border-box;
        tr.weatherTr {
            padding-top:10px;
            height:100px;
            border-top: 1px solid black;
            border-bottom: 1px solid black;
            background-color: white;
            font-weight: lighter;
            td {
                text-align:center;
                padding: 15px 0 15px 0;
                img {
                    width:60px;
                    height:60px;
                    padding-top:5px;
                }
                p {
                    padding: 0px;
                    margin: 0px;
                }
            }
            .alignR {
                text-align:right;
                padding-right:15px;
                font-family:"Roboto";
            }
        }
    }

    .weatherLb {
        font-size: 18px;
    }

    .topLineTr {
        border-top:1px solid black;
        height:10px;
    }

    .alignC {
        text-align:center;
        font-weight:bold;
    }
    .verticalLineTd{
        position: relative;
    }
    .verticalLine {
        float:left;
        height:50px;
        width:1px;
        position: relative;
        border-left:1px solid black;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 25px;
    }

    .sm {
        font-size:16px;
    }
    .topTxt {
        font-size: 10px;
        vertical-align: top;
    }
    .alignL {
        text-align:left;
        font-family:"Roboto";
    }
`;

export default WeatherItem; 