import { Link } from 'react-router-dom';
import styled from "styled-components";
import Header from './Header';

const We = (props) => {

    return (
        <WeContainer>
            <Header/>
            <h2>Leader</h2>
            Kwon<br/>
            <h3>Member</h3>
            Kim<br/>
            Kim<br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className='licenseLb'>
                <a href="mailto:nightkms@gmail.com">contact us</a>
            </div>

            <Link className='about' to={"/?go=main"}>Back.</Link>
        </WeContainer>
    );
}

const WeContainer = styled.div`
    .licenseLb {
        position: absolute;
        bottom: 50px;
        width: 100%;
        left: 0px;
    }
`

export default We; 