import { useEffect, useState } from 'react';
import styled from "styled-components";
import Header from './Header';
import Biz from '../common/common';

const WeatherInfo = (props) => {
    
    const [state, setState] = useState({});
    const [datetime, setDatetime] = useState("2011년 12월 12일 11시 29분 기준");
    
    useEffect(() => {

        window.gtag("event", "Page_리포트");

        let item = JSON.parse(localStorage.getItem("_mwInfo"));

        // console.log(item);
        
        // console.log("날씨 상태" , item.conditionLv);
        // console.log("강수량" , item.precipitation);
        // console.log("적설량" , item.snowfall);
        // console.log("최저온도" , item.minTemp);
        // console.log("최고온도" , item.maxTemp);
        // console.log("습도" , item.humidity);
        // console.log("풍속" , item.windSpeed);
        // console.log("시작시간" , item.startTime);
        // console.log("종료시간" , item.destTime);
        // console.log("비여부" , item.isRain);
        // console.log("소나기여부" , item.isShower);
        // console.log("강우확률" , item.rainRate);
        // console.log("체감온도" , item.windChill);

        if(item.conditionLv === 0) {
            item.weatherIcon = "sun.png";
        } else if(item.conditionLv === 1) {
            item.weatherIcon = "cloudy.png";
        } else if(item.conditionLv === 2) {
            item.weatherIcon = "overcast.png";
        } else if(item.conditionLv === 3) {
            item.weatherIcon = "snowy.png";
        } else if(item.conditionLv === 4) {
            item.weatherIcon = "rainy.png";
        } else if(item.conditionLv === 5) {
            item.weatherIcon = "shower.png";
        } else if(item.conditionLv === 6) {
            item.weatherIcon = "rainsnow.png";
        }
        
        // console.log("시작시간" , item.startTime);
        // console.log("종료시간" , item.destTime);

        let startTimeHour   = parseInt(item.startTime.substring(0,2));
        let startTimeMinute   = parseInt(item.startTime.substring(2,4));

        if(startTimeMinute === 0) {
            startTimeMinute = "";
        }

        let destTimeHour   = parseInt(item.destTime.substring(0,2));
        let destTimeMinute   = parseInt(item.destTime.substring(2,4));

        if(destTimeMinute === 0) {
            destTimeMinute = "";
        }

        if(parseInt(startTimeHour) > 12) {
            item.startTime  = startTimeHour - 12 + (startTimeMinute === "" ? "" : ":" + startTimeMinute) + " PM";
        } else {
            item.startTime  = startTimeHour + (startTimeMinute === "" ? "" : ":" + startTimeMinute) + " AM";
        }

        if(parseInt(destTimeHour) > 12) {
            item.destTime  = destTimeHour - 12 + (destTimeMinute === "" ? "" : ":" + destTimeMinute) + " PM";
        } else {
            item.destTime  = destTimeHour + (destTimeMinute === "" ? "" : ":" + destTimeMinute) + " AM";
        }
        
        item.isLoad = true;
        setState(item);

        let dt  = item.date.substring(0,4) + "-"
                + item.date.substring(4,6) + "-"
                + item.date.substring(6,8) + " "
                + item.time.substring(0,2) + ":"
                + item.time.substring(2,4) + "기준";

        setDatetime(dt);

    }, [])

    return (
        <WeatherInfoContainer>
            <Header></Header>
            <label className='scdFont titleLb'>날씨</label><br/>
            {/* <label className='itemLb'>{Biz.DateUtil.getToday()}</label> */}
            <label className='timeLb'>{state.startTime} ~ {state.destTime}</label>
            <div className='weatherRoundBox'></div>
            {state.weatherIcon === undefined ? null
                : <img className='weatherImg' src={"/img/" + state.weatherIcon}></img>
            }
            <div className='tempLb'>{state.minTemp}<label className='topTxt'>o</label>~ {state.maxTemp}<label className='topTxt'>o</label></div>
            <div className='rainRateLb'>{state.rainRate}<label className='topTxt'>%</label></div>
            <div className={'weatherInfoLb ' + (!state.isLoad ? 'hide' : '')}>
                {
                    state.isRain
                    ? <>
                        오늘 일정에 비(눈)이/가 예보되어 있습니다. 우산을 챙기세요. 
                        {
                        state.isShower
                        ? <>소나기를 주의하세요.</>
                        : null
                        }<br/>
                    </>
                    : null
                }
                {
                    state.minTemp 
                    ? <>
                        최저 기온은 {state.minTemp}도 이며 최고 기온은 {state.maxTemp}도 입니다.<br/>
                    </>
                    : null
                }
                {
                    state.windChill && state.windChill < 0
                    ? <>체감 온도가 {state.windChill}도까지 떨어집니다. 두꺼운 겉옷을 준비해주세요.<br/></>
                    : null
                }
                습도는 {state.humidity}%입니다. <br/>
                {
                    state.humidity && state.humidity <= 40
                    ? <>습도가 낮습니다. 가습을 해주시고 물을 많이 마셔주세요.<br/></>
                    : null
                }
                
                풍속은 {state.windSpeed}m/s입니다.<br/>
                {
                    state.windSpeed && state.windSpeed > 5
                    ? <>강한 바람이 예상됩니다.<br/></>
                    : null
                }
                {/* [$오늘은 절기로 동지입니다]<br/> */}

            </div>
            <label className='about'>{datetime}</label>
        </WeatherInfoContainer>
    );
}

const WeatherInfoContainer = styled.div`
    font-family:"S-Core-Dream";

    .titleLb {
        color: rgb(64 64 64);
        font-size: 25px;
        font-weight: 600;
    }
    .itemLb {
        font-size:15px;
        color:#353E5E;
    }
    .timeLb {
        font-size:20px;
        color:#353E5E;
        font-weight:bold;
        font-family: S-Core-Dream;
        letter-spacing: -1px;
    }

    .weatherRoundBox {
        box-sizing: border-box;
        position: absolute;
        width: 113px;
        height: 113px;
        left: 51px;
        top: 240px;
        background: linear-gradient(165.75deg, #F4F2F2 7.77%, #EAEAEA 94.11%);
        box-shadow: -7.78321px -7.78321px 31.1329px rgba(255, 255, 255, 0.85), 7.78321px 7.78321px 31.1329px -3.11329px rgba(91, 115, 134, 0.78);
        border-radius: 96.5118px;
    }

    .tempLb{
        position: absolute;
        width: 131px;
        height: 35px;
        left: 205px;
        top: 250px;
        font-size: 26px;
        color: #393838;
        font-family: 'Roboto';
    }

    .rainRateLb{
        position: absolute;
        width: 131px;
        height: 35px;
        left: 205px;
        top: 300px;
        font-size: 26px;
        color: #393838;
        font-family: 'Roboto';
    }

    .weatherImg {
        position: absolute;
        width: 77.24px;
        height: 79.39px;
        left: 70px;
        top: 253px;
    }

    .weatherInfoLb {
        position: absolute;
        top: 400px;
        text-align:left;
        font-weight: 300;
        font-size: 20px;
        line-height: 30px;
        margin: 0 10px 0 10px;
        font-family: S-Core-Dream;
    }

    .hide {
        display:none;
    }
    
    .topTxt {
        font-size: 10px;
        vertical-align: top;
    }

    .about {
        font-size: 13px;
        position: fixed;
        right: 0;
        bottom: 0;
        margin: 15px;
    }
    
`;
export default WeatherInfo; 