import React, { useEffect, useState, useRef } from 'react';
import WeatherItem from './WeatherItem';
import Header from './Header';
import styled, {keyframes} from "styled-components";
import Biz from '../common/common';
import { useNavigate } from 'react-router-dom';

const WeatherMain = (props) => {
    let navagate = useNavigate();

    const [state, setState] = useState({
        todayLb : 'Today',
        tomorrowLb : 'Tomorrow',
    })

    const [todayArr, setTodayArr] = useState([]);
    const [tomorrowArr, setTomorrowArr] = useState([]);
    const [list, setList] = useState([]);
    const [showReportBtn, setShowReportBtn] = useState(false);
    const [replayReportBtn, setRePlayReportBtn] = useState(false);
    const weatherData   = useRef([]);

    const itemLoadComplete = (item) => {
        weatherData.current.push(item);
        
        if(list && list.length > 0 && list.length * 2 === weatherData.current.length) {
            
            let earlyTime = "2359";
    
            //현재 시간이 시작 시간보다 30분 빠른 경우 Info 페이지로 이동
            if(list && list.length > 0) {
                for(let i in list) {
                    if(list[i].startHour + list[i].startMinute < earlyTime) {
                        earlyTime = list[i].startHour + list[i].startMinute
                    }
                }
            }

            if(earlyTime > Biz.DateUtil.getTime()
                // && earlyTime - Biz.DateUtil.getTime() < 30
                ) {
                setShowReportBtn(true);
            } else {
                let reportData = JSON.parse(localStorage.getItem("_mwInfo"));

                if(reportData && reportData.date  === Biz.DateUtil.getDate()) {
                    setRePlayReportBtn(true);
                }
            }
        }
    }

    const reportClick = () => {
        // console.log(weatherData.current);

        let startTime   = "2359";
        let destTime    = "0000";

        if(list && list.length > 0) {
            for(let i in list) {
                if(list[i].startHour + list[i].startMinute < startTime) {
                    startTime = list[i].startHour + list[i].startMinute
                }
                if(list[i].destHour + list[i].destMinute > destTime) {
                    destTime = list[i].destHour + list[i].destMinute
                }
            }
        }

        let conditionLv     = 0;    //날씨 상태
        let isRain          = false;//비 여부
        let isShower        = false;//소나기 여부
        let precipitation   = "";   //강수량
        let snowfall        = "";   //적설량
        let minTemp         = "";   //최저온도
        let maxTemp         = "";   //최고온도
        let humidity        = "";   //습도
        let windSpeed       = "";   //풍속
        let rainRate        = "";   //강수확률
        let windChill       = "";   //체감온도

        for(let i in weatherData.current) {
            let item = weatherData.current[i];

            let prefix  = "start";
            if(item.destDate) {
                prefix  = "dest";
            }

            if(i === "0") {
                conditionLv     = Biz.Const.WEATHER_LVL[item[prefix + "Weather"]];
                minTemp         = parseInt(item[prefix + "Temp"]);
                maxTemp         = parseInt(item[prefix + "Temp"]);
                humidity        = item[prefix + "Humidity"];
                windSpeed       = item[prefix + "WindSpeed"];
                rainRate        = item[prefix + "RainRate"];
                windChill       = Biz.StringUtil.getTempToWindChill(minTemp, windSpeed);
                
                if(item[prefix + "Weather"] === "Snow") {
                    snowfall        = item[prefix + "Precipitation"];
                } else {
                    precipitation   = item[prefix + "Precipitation"];
                }
                
            } else {
                //이전이랑 비교해서 넣자
                if(conditionLv < Biz.Const.WEATHER_LVL[item[prefix + "Weather"]] ) {
                    conditionLv = Biz.Const.WEATHER_LVL[item[prefix + "Weather"]];
                }

                if(item[prefix + "Weather"] === "Snow") {
                    if(snowfall < item[prefix + "Precipitation"]) {
                        snowfall = item[prefix + "Precipitation"]
                    }
                } else {
                    if(precipitation < item[prefix + "Precipitation"]) {
                        precipitation = item[prefix + "Precipitation"]
                    }
                }

                if(minTemp > parseInt(item[prefix + "Temp"])) {
                    minTemp = parseInt(item[prefix + "Temp"]);
                }

                if(maxTemp < parseInt(item[prefix + "Temp"])) {
                    maxTemp = parseInt(item[prefix + "Temp"]);
                }

                if(humidity < item[prefix + "Humidity"] ) {
                    humidity = item[prefix + "Humidity"];
                }

                if(windSpeed < item[prefix + "WindSpeed"] ) {
                    windSpeed = item[prefix + "WindSpeed"];
                }

                if(rainRate < item[prefix + "RainRate"] ) {
                    rainRate = item[prefix + "RainRate"];
                }

                if(windChill > Biz.StringUtil.getTempToWindChill(minTemp, windSpeed) ) {
                    windChill = Biz.StringUtil.getTempToWindChill(minTemp, windSpeed);
                }
            }
            
            if(Biz.Const.WEATHER_LVL[item[prefix + "Weather"]] >= 3) {
                isRain = true
            }
            if(Biz.Const.WEATHER_LVL[item[prefix + "Weather"]] === 5) {
                isShower    = true;
            }
        }

        let data    = {}
        data.conditionLv    = conditionLv;
        data.precipitation  = precipitation;
        data.snowfall       = snowfall;
        data.minTemp        = minTemp;
        data.maxTemp        = maxTemp;
        data.humidity       = humidity;
        data.windSpeed      = windSpeed;
        data.startTime      = startTime;
        data.destTime       = destTime;
        data.isRain         = isRain;
        data.isShower       = isShower;
        data.rainRate       = rainRate;
        data.windChill      = windChill;
        data.date           = Biz.DateUtil.getDate();
        data.time           = Biz.DateUtil.getTime();

        localStorage.setItem("_mwInfo", JSON.stringify(data));

        navagate("/weatherinfo.html");
    }

    useEffect(() => {
        
        window.gtag("event", "Page_예보");

        // console.log("componentDidMount WeatherMain");

        let list = JSON.parse(localStorage.getItem("plan"));
        if(!list) {
            list = [];
        }

        let todayArr      = [];
        let tomorrowArr   = [];
        let isNextDay     = false;

        for(let i = 0; i < list.length; i++) {
            let item    = list[i];
            isNextDay   = false;
            
            if(item.startHour + item.startMinute > 
                item.destHour + item.destMinute) {
                isNextDay   = true;
            }

            if(isNextDay || item.destHour + item.destMinute >= Biz.DateUtil.getTime()) {
                todayArr.push(item);

                let now = new Date();
                let day = Biz.DateUtil.getDate();
                state.todayLb  = Biz.Const.WEEK_KOR_STR[ now.getDay()] + "요일 " + day.substring(4,6) + "." + day.substring(6);
            } else {
                tomorrowArr.push(item);
                let now = new Date();
                now.setDate(now.getDate() + 1);

                let day = Biz.DateUtil.getDate(1, "D");
                state.tomorrowLb  = Biz.Const.WEEK_KOR_STR[ now.getDay()] + "요일 " + day.substring(4,6) + "." + day.substring(6, 8);
            }
        }

        setTodayArr(todayArr)
        setTomorrowArr(tomorrowArr);
        setList(list);
        
    }, []);

    return( //HTML을 웹 페이지에 렌더링 한다. 
        <WeatherMainContainer>
            <Header></Header>
            <label className='scdFont titleLb'>예보</label>
            {
                todayArr && todayArr.length > 0
                ?
                    <div className='weaherItemBox'>
                        <div className='dayLb scdFont'>{state.todayLb}</div>
                        {
                            todayArr.map(
                                (item, i) => (
                                <WeatherItem data={item} key={'weatherStart' + i} onLoad={itemLoadComplete}/>
                                )
                            )
                        }
                    </div>
                : null
            }
            {
                tomorrowArr && tomorrowArr.length > 0
                ?
                    <div className='weaherItemBox'>
                        <div className='dayLb'>{state.tomorrowLb}</div>
                        {
                            tomorrowArr.map(
                                (item, i) => (
                                    <WeatherItem data={item} key={'weatherDest' + i} onLoad={itemLoadComplete}/>
                                )
                            )
                        }
                    </div>
                : null
            }
            {
                showReportBtn
                ?
                <div className='reportBtn' onClick={reportClick}>
                    <span>
                        오늘의 날씨 리포트가 도착했습니다.
                    </span>
                </div>
                :null
            }
            {
                replayReportBtn
                ?
                <div className='reportBtn' onClick={() => navagate("/weatherinfo.html")}>
                    <span>
                        오늘의 리포트 다시보기
                    </span>
                </div>
                :null
            }
            <div className='copyright'>자료 출처 : 기상청</div>
        </WeatherMainContainer>
    );
}

const btnSlide = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const WeatherMainContainer = styled.div`

font-family: S-Core-Dream;
padding-bottom: 60px;

.titleLb {
    color: rgb(64 64 64);
    font-size: 25px;
    font-weight: 600;
}

>label {
    color: rgb(64 64 64);
    font-size: 30px;
    font-weight: 600;
}
.dayLb {
    color: rgb(0 112 192);
    font-size: 20px;
    font-weight: 600;
    padding: 15px 0 30px 0;
}
.reportBtn {
    height: 55px;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    span {
        width: 90%;
        border: 1px solid #10bffa;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 35px 35px 0px 0px;
        font-weight: bold;
        animation-duration: 1s;
        animation-name: ${btnSlide};
    }
}
.copyright {
    margin: 5px;
    text-align: right;
    font-size: 13px;
}
`

export default WeatherMain; 